import { CloseOutlined, DownOutlined, MenuOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Ic_Arrowup from 'assets/icon/Ic_Arrow-up.png';
import Ic_Friendly from 'assets/icon/Ic_Friendly.png';
import Ic_Logo from 'assets/icon/Ic_Logo.svg';
import Ic_LogoJPG from 'assets/icon/Ic_Logo.jpg';
import { BUTTON_STYLES } from 'components/button/types';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import i18n from 'components/i18n';

export const Navbar = () => {

    const [toogle, setToogle] = useState<boolean>(false);

    const onChangeToogle = useCallback(() => {
        setToogle(!toogle)
    }, [toogle])

    const { t } = useTranslation()

    function changeLanguage(e) {
        i18n.changeLanguage(e.target.value);
    }

    return (
        <div className='header-container'>
            <div className='container'>
                <div className='menu-bar--navigation'>
                    <div className='menu-left'>
                        <div className='logo'>
                            <Link to="/">
                                <img src={Ic_LogoJPG} alt='logo' />
                            </Link>
                        </div>
                        <div className='hidden-md hidden-lg'>

                            <div className='navbar-button' onClick={onChangeToogle}>
                                {!toogle ? <MenuOutlined rev={undefined} /> : <CloseOutlined rev={undefined} />}
                            </div>

                        </div>
                        <div className={`menu ${toogle ? 'actve' : 'disable'}`}>
                            <div className='navigation'>
                                <ul className='nav-menu'>
                                    <li className='nav-item'><Link className='active' to='/'>{t('content.Solutions')} <DownOutlined rev={undefined} /></Link></li>
                                    <li className='nav-item'><Link to='/'>{t('content.Features')} <DownOutlined rev={undefined} /></Link></li>
                                    <li className='nav-item'><Link to='/'>{t('content.Customer')} <DownOutlined rev={undefined} /></Link></li>
                                    <li className='nav-item'><Link to='/'>{t('content.Resources')} <DownOutlined rev={undefined} /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`menu-right ${toogle ? 'actve' : 'disable'}`}>
                        <div className='button_sellect'>

                            {/* <Button
                                label={'Friendly Support'}
                                buttonStyle={BUTTON_STYLES.GREEN}
                                textStyle={BUTTON_STYLES.BLACK}
                                rightIcon={Ic_Friendly}
                                isLowerCase
                                customStyles={{ borderRadius: 50 }}
                            /> */}

                            <Button
                                label={t('content.BuildAnApp')}
                                buttonStyle={BUTTON_STYLES.GREEN}
                                textStyle={BUTTON_STYLES.BLACK}
                                rightIcon={Ic_Arrowup}
                                isLowerCase
                                customStyles={{ borderRadius: 50 }}
                                
                            />

                        </div>
                        <div className='sellect-language'>
                            <select onChange={changeLanguage} name='select_language' className='select_language'>
                                <option defaultValue='/en/' value='en'>EN </option>
                                <option defaultValue='/fr/' value='fr'>FR </option>
                                <option defaultValue='/ge/' value='ge'>GE </option>
                                <option defaultValue='/en/' value='cn'>CN </option>
                            </select>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};



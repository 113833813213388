import React, { useCallback } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Ic_Media_1 from 'assets/icon/Ic_Media_1.gif';
import Ic_Media_2 from 'assets/icon/Ic_Media_2.gif';
import Ic_Media_3 from 'assets/icon/Ic_Media_3.svg';
import Ic_Media_4 from 'assets/icon/Ic_Media_4.gif';
import Ic_Media_5 from 'assets/icon/Ic_Media_5.gif';
import Ic_Media_6 from 'assets/icon/Ic_Media_6.svg';
import Ic_Media_7 from 'assets/icon/Ic_Media_7.gif';
import Ic_Media_8 from 'assets/icon/Ic_Media_8.gif';
import Img_video from 'assets/icon/video11.mp4';
import { Trans, Translation } from 'react-i18next';
import ReactPlayer from 'react-player';

const Section_4 = () => {

    const renderItemSolution = useCallback(
        (title: string, img: any) => {
            return <div className='item_solution'>
                <AnimationOnScroll
                    animateIn="animate__zoomIn"
                    offset={0}
                    duration={2}
                >
                    <div className='item_icon'>
                        <div className='icon_bg'>
                            <img src={img} alt={title} className='icon'
                                style={{ alignSelf: 'center' }}
                            />
                        </div>
                    </div>
                    <h4 className='text'>
                        <Translation>
                            {
                                (t, { i18n }) => t(title)
                            }
                        </Translation></h4>
                </AnimationOnScroll>
            </div >
        },
        [],
    )

    return (
        <section className='section section_4'>
            <div className='wrapper_box_solution'>
                <div className='container'>
                    <div className='box_solution_style'>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Solution')

                                        }
                                    </Translation></h2>
                            </div>
                        </div>

                        <div className='solution_business_item'>

                            <div className='top'>
                                {renderItemSolution('content.ApplicationServers', Ic_Media_1)}
                                {renderItemSolution('content.LoadBalancers', Ic_Media_2)}
                                {renderItemSolution('content.DatabaseServers', Ic_Media_3)}
                            </div>

                            <div className='bottom'>
                                {renderItemSolution('content.AppPublishingPlatforms', Ic_Media_4)}
                                {renderItemSolution('content.SDKsSoftware', Ic_Media_5)}
                                {renderItemSolution('content.APIs', Ic_Media_6)}
                                {renderItemSolution('content.CDNContent', Ic_Media_7)}
                                {renderItemSolution('content.ObjectStorage', Ic_Media_8)}
                                <div className='clear'></div>
                            </div>

                        </div>

                        <div className='video_box_solutions'>
                            <ReactPlayer url={Img_video} width="100%" height="100%" controls={true} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_4
import React, { useState, useRef, ChangeEvent, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { TextFieldActions } from './input/types';
import { Button } from './button';
import { BUTTON_STYLES } from './button/types';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import { MyTextInput } from './input';
import { Translation } from 'react-i18next';

const Modalform = forwardRef<any>((props, ref) => {

    const refunderfine = useRef<TextFieldActions>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [firstname, setFirstname] = useState<any>('');
    const [lastname, setLastname] = useState<any>('');
    const [website, setWebsite] = useState<any>('');
    const [namecompany, setNamecompany] = useState<any>('');
    const [businessEmail, setBusinessEmail] = useState<any>('');
    const [phone, setPhone] = useState<any>('');
    const [phoneCode, setPhoneCode] = useState<any>('');
    const [companysize, setCompanysize] = useState<any>('');
    const [jobRole, setJobRole] = useState<any>('');

    const hideModal = () => {
        setOpen(false);
    };

    const showModal = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    const onChangePhoneCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPhoneCode(event.target.value);
    };

    const onChangeJobRole = (event: ChangeEvent<HTMLSelectElement>) => {
        setJobRole(event.target.value)
    }

    const onChangeCompanySize = (event: ChangeEvent<HTMLSelectElement>) => {
        setCompanysize(event.target.value)
    }

    const onChangeText = useCallback((event: ChangeEvent<HTMLInputElement>, placeHolder: any) => {

        switch (placeHolder) {
            case 'First Name':
                setFirstname(event)
                break;

            case 'Last Name':
                setLastname(event)
                break;

            case 'Business Email':
                setBusinessEmail(event)
                break;

            case 'Phone Number':
                setPhone(event)
                break;

            case 'Company Name':
                setNamecompany(event)
                break;

            case 'Website':
                setWebsite(event)
                break;

            default:
                break;
        }

    }, [])

    const onChangeOpenModal = useCallback(() => {
        setOpen(true)
    }, [])

    const renderInput = useCallback((refs?: any, values?: string, placeHolder?: any) => {
        return <MyTextInput
            ref={refs}
            value={values}
            type={'text'}
            placeHolder={placeHolder}
            maxLength={300}
            onChangeText={onChangeText}
            inputStyle={'inputStyle'}
        />

    }, [])

    const onChangeSendEmail = () => {
        if (`${firstname}${lastname}${website}${phone}${namecompany}${businessEmail}${phoneCode}${companysize}${jobRole}`.length === 0) {
            const formData = new FormData();
            formData.append('firstname', firstname);
            formData.append('lastname', lastname);
            formData.append('businessEmail', businessEmail);
            formData.append('namecompany', namecompany);
            formData.append('phone', `${phoneCode} ${'-'} ${phone}`);
            formData.append('jobRole', jobRole);
            formData.append('companysize', companysize);
            formData.append('website', website);
            fetch('https://formspree.io/f/xlekkykg', {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json'
                }
            }).then(response => response.json())
                .then(data => {
                    if (data.ok) {
                        Modal.success({
                            title: 'Message sent successfully',
                            content: (
                                <div>
                                    <p style={{ margin: 0 }}>
                                        <Translation>
                                            {
                                                (t, { i18n }) => t('content.WeWillContact')

                                            }
                                        </Translation>
                                    </p>
                                    <p style={{ margin: 0 }}>
                                        <Translation>
                                            {
                                                (t, { i18n }) => t('content.ThankYouInterest')

                                            }
                                        </Translation>
                                    </p>
                                </div>
                            )
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });

        } else {
            Modal.error({
                title: 'Message sent error',
                content: (
                    <div>
                        <p style={{ margin: 0 }}>
                            <Translation>
                                {
                                    (t, { i18n }) => t('content.CompleteInformation')

                                }
                            </Translation>.</p>
                    </div>
                )
            });
            return false
        }
    };

    const modal = useMemo(() => {
        return <Translation>
            {
                (t, { i18n }) => <Modal
                    title={t('content.CompleteInformation')}
                    centered
                    open={open}
                    onOk={() => setOpen(false)}
                    onCancel={() => setOpen(false)}
                    closeIcon={<CloseOutlined />}
                    width={480}
                    footer={null}
                    className='modal_get_started'
                >

                    <div className='row'>
                        <div className='col-xs-12 col-sm-6 col-md-6'>

                            {renderInput(refunderfine, firstname, t('content.FirstName'))}

                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            {renderInput(refunderfine, lastname, t('content.LastName'))}

                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            {renderInput(refunderfine, businessEmail, t('content.BusinessEmail'))}
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            <div className='name_phone_code'>
                                <select value={phoneCode} onChange={onChangePhoneCode} className='name_phone_code sellect_option_form'>
                                    <option value="-1">Code</option>
                                    <option value="English(+44)">English</option>
                                    <option value="France(+33)">France</option>
                                    <option value="Taiwan(+886)">Taiwan</option>
                                    <option value="Germany(+49)">Germany</option>
                                </select>
                                {renderInput(refunderfine, phone, t('content.PhoneNumber'))}
                            </div>


                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            {renderInput(refunderfine, namecompany, t('content.CompanyName'))}
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            <select value={jobRole} onChange={onChangeJobRole} className='name_phone_code sellect_option_form'>
                                <option value="Job Role">{t('content.JobRole')}</option>
                                <option value="Ceo / Owner">{t('content.CeoOwner')}</option>
                                <option value="Middle Management">{t('content.MiddleManagement')}</option>
                                <option value="Professional">{t('content.Professional')}</option>
                                <option value="Admin/Support Staff">{t('content.AdminSupportStaff')}</option>
                                <option value="Student">{t('content.Student')}</option>
                                <option value="Other">{t('content.Other')}</option>
                            </select>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            <select value={companysize} onChange={onChangeCompanySize} className='name_phone_code sellect_option_form'>
                                <option value="none">{t('content.CompanySize')}</option>
                                <option value="1">1</option>
                                <option value="2-10">2-10</option>
                                <option value="11-50">11-50</option>
                                <option value="51-200">51-200</option>
                                <option value="201-500">201-500</option>
                                <option value="501-1000">501-1000</option>
                                <option value="1001-5000">1001-5000</option>
                                <option value="5001-10,000">5001-10,000</option>
                                <option value="10,000+">10,000+</option>
                            </select>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                            {renderInput(refunderfine, website, 'Website')}
                        </div>
                        <div className='col-xs-12'>
                            <Button
                                label={'Submit'}
                                buttonStyle={BUTTON_STYLES.LINEAR_GREEN}
                                isLowerCase
                                customStyles={{ borderRadius: 50 }}
                                autocenter
                                onPress={onChangeSendEmail}
                            />
                            <p>
                                {t('content.Agree')} <Link to={'/'}> {t('content.Terms')} </Link> {t('content.And')} <Link to={'/'}>{t('content.PrivacyPolicy')

                                }</Link>
                            </p>
                        </div>
                        <div className='clear'></div>
                    </div>

                </Modal>
            }
        </Translation>

    }, [open, phoneCode, firstname, lastname, website, namecompany, businessEmail, companysize, jobRole, phone])

    return (
        modal
    )
});

export default Modalform
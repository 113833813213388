import classNames from 'classnames/bind';
import React, { useState, useRef, ChangeEvent, useCallback } from 'react';
import styles from './home.module.scss';
import { Navbar } from 'components/menu';
import Ic_arrowRight from 'assets/icon/Ic_arrow-right.png';
import Ic_Play from 'assets/icon/Ic_Play.png';
import Img_Laptop from 'assets/icon/img_laptop.png';
import Img_Phone_2 from 'assets/icon/Img_Phone_2.svg';
import Img_Phone_3 from 'assets/icon/Img_Phone_3.svg';
import { Button } from 'components/button';
import { BUTTON_STYLES } from 'components/button/types';
import Ic_dowload from 'assets/icon/Ic_dowload.svg';
import Ic_UploadSimple from 'assets/icon/Ic_UploadSimple.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MyTextInput } from 'components/input';
import { MyTextAreaInput } from 'components/text-area';
import { TextFieldActions } from 'components/input/types';
import formValidate from 'utils/form-validate';
import Languages from 'commons/languages';
import { Modal } from 'antd';
import Section_1 from './section_1';
import Section_2 from './section_2';
import Section_3 from './section_3';
import Section_4 from './section_4';
import Section_5 from './section_5';
import Section_6 from './section_6';
import Section_7 from './section_7';
import { saveAs } from 'file-saver';
import Modalform from 'components/modalform';
import { Trans, Translation } from 'react-i18next';

const cx = classNames.bind(styles);

const filePath = 'https://dataxmind.com/SurveyForClients_of_DataXMind_Company.xlsx';

function Home() {

    const [name, setName] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [content, setContent] = useState<any>('');

    const refModal = useRef<any>(null);
    const refName = useRef<TextFieldActions>(null);
    const refEmail = useRef<TextFieldActions>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event);
    };

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event);
    };

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFileName(`${file.name} (${file.type})`);
            // Xử lý file tại đây
        }
    };

    const onChangeOpenModal = useCallback(() => {
        refModal?.current?.showModal();
    }, [])

    const handleSubmit = () => {

 
        const errMsgName = formValidate.inputNameEmpty(name, Languages.errorMsg.nameRequired, Languages.errorMsg.userNameRegex)
        const errMsgEmail = formValidate.emailValidate(email)

        refEmail.current?.setErrorMsg(errMsgEmail)
        refName.current?.setErrorMsg(errMsgName)

        if (`${errMsgName}${errMsgEmail}`.length === 0) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('content', content);
            //formData.append('file', fileInputRef.current?.files?.[0] || '');
            fetch('https://formspree.io/f/moqgnbbw', {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json'
                }
            }).then(response => response.json())
                .then(data => {
                    if (data.ok) {
                        Modal.success({
                            title: 'Message sent successfully',
                            content: (
                                <div>
                                    <p style={{ margin: 0 }}>
                                        <Translation>
                                            {
                                                (t, { i18n }) => t('content.WeWillContact')

                                            }
                                        </Translation></p>
                                    <p style={{ margin: 0 }}>
                                        <Translation>
                                            {
                                                (t, { i18n }) => t('content.ThankYouInterest')

                                            }
                                        </Translation></p>
                                </div>
                            )
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });

        } else {
            return false
        }
    };


    const settingsSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000

    };

    const handleDownload = () => {
        saveAs(filePath, 'SurveyForClients_of_DataXMind_Company.xlsx');
    };

    return (
        <div className={cx('page')}>
            <div className='wrap_header'>
                <Navbar />
                <div className='wrap_box_slide'>
                    <div className='container'>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                            <div className='slide_box_left'>
                                <h1 className='title_web fadeIn1'>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.DataXmind')

                                        }
                                    </Translation></h1>
                                <p className='descreption fadeIn2'>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Powerfull')

                                        }
                                    </Translation></p>
                                <div className='content fadeIn3'>
                                    <p><Translation>
                                        {
                                            (t, { i18n }) => t('content.MakeRapidlyMobile')

                                        }
                                    </Translation></p>
                                    <ul>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' />
                                            <Translation>
                                                {
                                                    (t, { i18n }) => t('content.Functionality')

                                                }
                                            </Translation> </li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' />
                                            <Translation>
                                                {
                                                    (t, { i18n }) => t('content.Intuitive')

                                                }
                                            </Translation></li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' />
                                            <Translation>
                                                {
                                                    (t, { i18n }) => t('content.Personalise')

                                                }
                                            </Translation> </li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' />
                                            <Translation>
                                                {
                                                    (t, { i18n }) => t('content.Increase')

                                                }
                                            </Translation></li>
                                    </ul>
                                </div>
                                <div className='button animate__fadeInLeft_phone'>

                                    <Translation>
                                        {
                                            (t, { i18n }) => <Button
                                                label={t('content.GetStarted')}
                                                buttonStyle={BUTTON_STYLES.LINEAR}
                                                rightIcon={Ic_Play}
                                                isLowerCase
                                                customStyles={{ borderRadius: 50 }}
                                                onPress={onChangeOpenModal}
                                            />
                                        }
                                    </Translation>



                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                            <div className='swapper_slider-box fadeIn2'>
                                <Slider {...settingsSlider}>
                                    <div className='item_slide'>
                                        <img src={Img_Laptop} alt='Img_Laptop' />
                                    </div>
                                    <div className='item_slide'>
                                        <img src={Img_Phone_2} alt='Img_Laptop' />
                                    </div>
                                    <div className='item_slide'>
                                        <img src={Img_Phone_3} alt='Img_Laptop' />
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className='clear'></div>
                    </div>
                </div>
            </div>
            <Section_1 />
            <Section_2 />
            <Section_3 />
            <Section_4 />
            <Section_5 />
            <Section_6 />
            <Section_7 />
            <section className='section section_8'>
                <div className='form_wrappper'>
                    <div className='container'>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2><Trans i18nKey='content.SurveyContact' /></h2>
                            </div>
                        </div>
                        <div className='form_wrapper_input'>
                            <div className='dowloadFile'>
                                <div className='text_file_dowload'>
                                    <h3><Translation>
                                        {
                                            (t, { i18n }) => t('content.Download')

                                        }
                                    </Translation></h3>
                                    <p><Translation>
                                        {
                                            (t, { i18n }) => t('content.Survey')

                                        }
                                    </Translation></p>
                                </div>
                                <div className='file' onClick={handleDownload}>
                                    <input type='file' name='file' />
                                    <img src={Ic_dowload} alt='dowload' />
                                </div>
                            </div>
                            <div className='box_input_group'>
                                <div className='title'>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Whatever')

                                        }
                                    </Translation>
                                </div>
                                <Translation>
                                    {
                                        (t, { i18n }) => <MyTextInput
                                            ref={refName}
                                            value={name}
                                            type={'text'}
                                            placeHolder={t('content.YourName')}
                                            maxLength={30}
                                            onChangeText={handleNameChange}
                                        />
                                    }
                                </Translation>

                                <Translation>
                                    {
                                        (t, { i18n }) => <MyTextInput
                                            ref={refEmail}
                                            value={email}
                                            type={'text'}
                                            placeHolder={t('content.YourEmail')}
                                            maxLength={30}
                                            onChangeText={handleEmailChange}
                                        />
                                    }
                                </Translation>

                                <div className='input_file_box'>
                                    <input
                                        ref={fileInputRef}
                                        id="file"
                                        type='file'
                                        name='file'
                                        className='input_file'
                                        onChange={handleFileChange} />
                                    <label
                                        htmlFor="file"
                                    > {selectedFileName ? <Trans i18nKey='content.FileName' /> + `${selectedFileName}` : <Trans i18nKey='content.AttachFile' />}
                                        <img src={Ic_UploadSimple} alt='Ic_UploadSimple' />
                                    </label>

                                </div>

                                <Translation>
                                    {
                                        (t, { i18n }) => <MyTextAreaInput
                                            value={content}
                                            type={'text'}
                                            placeHolder={t('content.YourTextarea')}
                                            maxLength={300}
                                            onChangeText={handleTextChange}
                                        />
                                    }
                                </Translation>

                                <Translation>
                                    {
                                        (t, { i18n }) => <Button
                                            label={t('content.Send')}
                                            buttonStyle={BUTTON_STYLES.LINEAR_GREEN}
                                            textStyle={BUTTON_STYLES.WHITE}
                                            width={50}
                                            customStyles={{ justifyContent: 'center', margin: 'auto', marginTop: 30 }}
                                            onPress={handleSubmit}
                                        />
                                    }
                                </Translation>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.6679447170086!2d105.76292627605353!3d20.965845989934873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313452d924423889%3A0xfebb75e18dea06cd!2zTmfDtSAzIFBoYW4gxJDDrG5oIEdpw7N0LCBsw6BuZyBMYSBLaMOqLCBRdWFuZyBUcnVuZywgSMOgIMSQw7RuZywgSMOgIE7hu5lpLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1686103738479!5m2!1sen!2s" width="100%" height="450" loading="lazy" ></iframe>
            </div>
            <Modalform ref={refModal} />
        </div >
    );
}

export default Home;

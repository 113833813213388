import Languages from 'commons/languages';
import { Navbar } from 'components/menu';
import ContactForm from 'pages/contact/contact-form';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img_about_1 from '../../assets/icon/img_about_1.png';
import img_about_2 from '../../assets/icon/img_about_2.jpeg';
import img_about_3 from '../../assets/icon/img_about_3.png';
import img_about_4 from '../../assets/icon/img_about_4.jpeg';
import img_about_5 from '../../assets/icon/img_about_5.png';
import Footer from 'components/footer';

function About() {
    const navigate = useNavigate();
    // const { apiServices } = useAppStore();

    const onLogin = useCallback(async () => {
        // const response = await apiServices.common.checkAppState();
        // console.log(response);
        // userManager.updateDemo(response.data);
    }, [navigate]);

    return (
        <div className='column'>
            <Navbar />
            <div className={'center page-padding'}>
                {/* <img src={ImgHeader} className={cx('banner')}/> */}
                <span className='h5 y10'>
                    {Languages.about.content[0]}
                </span>
                <img src={img_about_1} className={'full-image'} />
                <img src={img_about_2} className={'full-image'} />
                <img src={img_about_3} className={'full-image'} />
                <img src={img_about_4} className={'full-image'} />
                <img src={img_about_5} className={'full-image'} />

                <div className='column y10 '>
                    <span className='h7 blue'>
                        {Languages.about.content[1]}
                    </span>
                    <span className='h5 medium blue y10'>
                        {Languages.about.content[2]}
                    </span>
                </div>
            </div>
            <div className='mision content-padding y10'>
                <span className='h6 medium y10'>
                    {Languages.about.content[3]}
                </span>
                <span className='h7 medium section'>
                    {Languages.about.content[4]}
                </span>
                <span className='h8'>
                    {Languages.about.content[5]}
                </span>

                <span className='h7 medium section'>
                    {Languages.about.content[6]}
                </span>
                <span className='h8'>
                    {Languages.about.content[7]}
                </span>

                <span className='h7 medium section'>
                    {Languages.about.content[8]}
                </span>
                <span className='h8'>
                    {Languages.about.content[9]}
                </span>

                <span className='h7 medium section'>
                    {Languages.about.content[10]}
                </span>
                <span className='h8'>
                    {Languages.about.content[11]}
                </span>

                <span className='h7 medium section'>
                    {Languages.about.content[12]}
                </span>
                <span className='h8 b10'>
                    {Languages.about.content[13]}
                </span>

            </div>
            <div className='content-padding y10'>
                <ContactForm />
            </div>
            <Footer/>
        </div>
    );
}

export default About;

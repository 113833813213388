import React, { useCallback, useRef } from 'react'
import { Button } from 'components/button'
import { BUTTON_STYLES } from 'components/button/types'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Img_Why from 'assets/icon/Img_Why.webp';
import Ic_arrowRight from 'assets/icon/Ic_arrow-right.png';
import Ic_Calendar from 'assets/icon/Ic_Calendar.png';
import Ic_Play from 'assets/icon/Ic_Play.png';
import Modalform from 'components/modalform';
import { Trans, Translation } from 'react-i18next';

const Section_3 = () => {
    const refModal = useRef<any>(null);
    const onChangeOpenModal = useCallback(() => {
        refModal?.current?.showModal();
    }, [])
    return (
        <section className='section section_3'>
            <div className='wrapper_choose-us0'>
                <div className='container'>
                    <div className='hidden-xs col-sm-6 col-md-6'>
                        <AnimationOnScroll

                            animateIn="animate__fadeInLeft"
                            offset={0}
                            duration={2}
                        >
                            <div className='wrapper_choose_box_left'>
                                <img src={Img_Why} alt='imgchooseleft' />
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <AnimationOnScroll

                            animateIn="animate__fadeInRight"
                            offset={0}
                            duration={2}
                        >
                            <div className='wrapper_choose_box_right'>
                                <div className='content'>
                                    <h2>
                                        <Translation>
                                            {
                                                (t, { i18n }) => t('content.WhyShould')

                                            }
                                        </Translation>
                                    </h2>
                                    <p><Trans i18nKey='content.Experience' /></p>
                                    <ul>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' /><Trans i18nKey='content.NoDowntime' /> </li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' /><Trans i18nKey='content.NoCrashes' /></li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' /><Trans i18nKey='content.FastLoadingSpeeds' />  </li>
                                        <li><img src={Ic_arrowRight} alt='Ic_arrowRight' /><Trans i18nKey='content.TopNotchSecurity' /> </li>
                                    </ul>
                                </div>
                                <div className='button'>
                                    <Translation>
                                        {
                                            (t, { i18n }) => <Button
                                                label={t('content.GetStartedNow')}
                                                buttonStyle={BUTTON_STYLES.LINEAR}
                                                rightIcon={Ic_Play}
                                                isLowerCase
                                                customStyles={{ borderRadius: 50 }}
                                                onPress={onChangeOpenModal}
                                            />
                                        }
                                    </Translation>



                                </div>
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <div className='col-xs-12 hidden-sm hidden-md hidden-lg'>
                        <AnimationOnScroll

                            animateIn="animate__fadeInRight"
                            offset={0}
                            duration={2}
                        >
                            <div className='wrapper_choose_box_left'>
                                <img src={Img_Why} alt='imgchooseleft' />
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <div className='clear'></div>
                </div>
            </div>
            <Modalform ref={refModal} />
        </section>
    )
}

export default Section_3
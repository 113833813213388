import React, { useCallback } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Trans, Translation } from 'react-i18next';

const Section_2 = () => {

    const renderItemSquareBox = useCallback(
        (title: string, count: string) => {
            return <div className='col-xs-12 col-sm-6 col-md-3'>
                <div className='square_box'>
                    <p><Trans i18nKey={title} /></p>
                    <div className='square'>
                        <p>{count}</p>
                    </div>
                </div>
            </div>
        },
        [],
    )

    return (
        <section className='section section_2'>
            <div className='wrapper_bridge_idea '>
                <div className='container'>
                    <div className='wrapper_style'>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Reality')

                                        }
                                    </Translation></h2>
                            </div>
                        </div>
                        <div className='list_square_box'>
                            <AnimationOnScroll

                                animateIn="animate__fadeInLeft"
                                offset={0}
                                duration={2}
                            >
                                {renderItemSquareBox('content.YearsOfExperience', '4+')}
                            </AnimationOnScroll>
                            <AnimationOnScroll

                                animateIn="animate__fadeInUp"
                                offset={0}
                                duration={2}
                            >
                                {renderItemSquareBox('content.ProjectsIssuesed', '35+')}
                            </AnimationOnScroll>
                            <AnimationOnScroll

                                animateIn="animate__fadeInDown"
                                offset={0}
                                duration={2}
                            >
                                {renderItemSquareBox('content.AppWebsite', '52+')}
                            </AnimationOnScroll>
                            <AnimationOnScroll

                                animateIn="animate__fadeInRight"
                                offset={0}
                                duration={2}
                            >
                                {renderItemSquareBox('content.OurCustomers', '30+')}
                            </AnimationOnScroll>



                            <div className='clear'></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_2
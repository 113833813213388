import React, { useState, useCallback, useRef } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { DoubleRightOutlined } from '@ant-design/icons';
import Img_idea_1 from 'assets/icon/Img_idea_1.svg';
import Img_idea_2 from 'assets/icon/Img_idea_2.svg';
import Img_idea_3 from 'assets/icon/Img_idea.svg';
import Img_idea_4 from 'assets/icon/Img_idea4.svg';
import { Button } from 'components/button';
import { BUTTON_STYLES } from 'components/button/types';
import Ic_Calendar from 'assets/icon/Ic_Calendar.png';
import Ic_Play from 'assets/icon/Ic_Play.png';
import Modalform from 'components/modalform';
import { Trans, Translation } from 'react-i18next';

interface Item {
    id: number;
    title: any;
    img: any;
}

const Section_5 = () => {

    const refModal = useRef<any>(null);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [transition, setTransition] = useState<boolean>(false);

    const items: Item[] = [
        {
            id: 1,
            title: 'content.Implement',
            img: Img_idea_1
        },
        {
            id: 2,
            title: 'content.Architect',
            img: Img_idea_2
        },
        {
            id: 2,
            title: 'content.Engineers',
            img: Img_idea_3
        },
        {
            id: 2,
            title: 'content.Strategic',
            img: Img_idea_4
        }
    ];


    const getVisibleItems = (): Item[] => {
        const endIndex = (activeIndex + 3) % items.length;
        if (endIndex >= activeIndex) {
            return items.slice(activeIndex, endIndex + 1);
        } else {
            return [...items.slice(activeIndex), ...items.slice(0, endIndex + 1)];
        }
    };

    const handleClick = (index: number): void => {
        setActiveIndex(index % items.length);
        setTransition(true);
    };

    const onChangeOpenModal = useCallback(() => {
        refModal?.current?.showModal();
    }, [])


    return (
        <section className='section section_5'>
            <div className='wrapper_idea_reality'>
                <div className='container'>
                    <div className='idea_reality_sytlelist'>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2><Translation>
                                    {
                                        (t, { i18n }) => t('content.Bring')

                                    }
                                </Translation></h2>
                                <p><Translation>
                                    {
                                        (t, { i18n }) => t('content.Developers')

                                    }
                                </Translation></p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container'>
                    <div className="slide ">
                        <div className={`slide-container ${transition ? 'transition' : ''}`}>
                            {getVisibleItems().map((item, index) => (
                                <div
                                    key={index}
                                    className={`slide-item ${index === 1 ? 'active' : ''}`}
                                    onClick={() => handleClick(activeIndex + 1)}
                                >
                                    <AnimationOnScroll

                                        animateIn="animate__fadeInRight"
                                        offset={0}
                                        duration={2}
                                    >
                                        <div className='box_image_scroll'>
                                            <img src={item?.img} alt={item?.title} />
                                        </div>
                                        <div className='title'>
                                            <h4>
                                                <Translation>
                                                    {
                                                        (t, { i18n }) => t(item?.title)

                                                    }
                                                </Translation></h4>
                                        </div>

                                        <div className='icon'>
                                            <div className={`icon_Round ${index === 1 ? 'active' : ''}`}>
                                                <DoubleRightOutlined rev={undefined} />
                                            </div>
                                        </div>
                                    </AnimationOnScroll>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='button'>
                        {/* <Button
                            label={'Schedule a consultation '}
                            buttonStyle={BUTTON_STYLES.GREEN}
                            textStyle={BUTTON_STYLES.BLACK}
                            rightIcon={Ic_Calendar}
                            isLowerCase
                            customStyles={{ borderRadius: 50 }}
                        /> */}
                        <Translation>
                            {
                                (t, { i18n }) => <Button
                                    label={t('content.GetStartedNow')}
                                    buttonStyle={BUTTON_STYLES.LINEAR}
                                    rightIcon={Ic_Play}
                                    isLowerCase
                                    customStyles={{ borderRadius: 50 }}
                                    onPress={onChangeOpenModal}
                                />
                            }
                        </Translation>


                        <Modalform ref={refModal} />
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Section_5
import { Button } from 'components/button'
import { BUTTON_STYLES } from 'components/button/types'
import React, { useCallback } from 'react'
import Ic_Play from 'assets/icon/Ic_Play.png';
import { Translation } from 'react-i18next';
import Ic_Item1 from 'assets/icon/item1.jpg';
import Ic_Item2 from 'assets/icon/item2.jpg';
import Ic_Item3 from 'assets/icon/item3.jpg';
import Ic_Item4 from 'assets/icon/item4.jpg';
import Ic_Item5 from 'assets/icon/item5.png';
import Ic_Item6 from 'assets/icon/item6.jpg';



const Section_1 = () => {

    const renderItemProduct = useCallback((title?: string, desc?: string, img?: any) => {
        return <div className='col-xs-12 col-sm-6 col-md-4 fadeIn3'>
            <div className='item'>
                <div className='box-image'>
                    <img className='image' src={img} alt='item' />
                    <div className='button_on-abs'>
                        <Button
                            label={'Get Started'}
                            buttonStyle={BUTTON_STYLES.LINEAR_GREEN}
                            rightIcon={Ic_Play}
                            isLowerCase
                            customStyles={{ borderRadius: 50 }}
                        />
                    </div>
                </div>
                <div className='box_wrapper_content' >
                    <h3>{title}</h3>
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    }, [])


    const renderTrans = useCallback((title?: any, desc?: any, img?: any) => {

        return <Translation>
            {
                (t, { i18n }) => renderItemProduct(t(title), t(desc), img)
            }
        </Translation>

    }, [])


    return (
        <section className='section section_1'>
            <div className='section_wrap_box_business'>
                <div className='container'>
                    <div className='wrap_box_style_cover'>
                        <div className='head'>
                            <h2>

                                <Translation>
                                    {
                                        (t, { i18n }) => t('content.EcommerceAppsTitle')

                                    }
                                </Translation>

                            </h2>
                        </div>
                        <div className='item_list_worked'>

                            {renderTrans('content.FullCustomMobileApps', 'content.FullCustomMobileAppsDesc', Ic_Item1)}
                            {renderTrans('content.eCommerceApps', 'content.eCommerceAppsDesc', Ic_Item2)}
                            {renderTrans('content.EntertainmentApps', 'content.EntertainmentAppsDesc', Ic_Item3)}
                            {renderTrans('content.FnBApps', 'content.FnBAppsDesc', Ic_Item4)}
                            {renderTrans('content.UtilityApps', 'content.UtilityAppsDesc', Ic_Item5)}
                            {renderTrans('content.IndustriesApps', 'content.IndustriesAppsDesc', Ic_Item6)}

                            <div className='clear'></div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_1
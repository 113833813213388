import React, { memo, useCallback } from 'react';
import styles from './footer.module.scss';
import Ic_Logo from 'assets/icon/Ic_Logo.svg';
import { Link } from 'react-router-dom';
import Ic_Phone from 'assets/icon/Ic_Phone.png';
import Ic_globe from 'assets/icon/Ic_globe.png';
import Ic_mail from 'assets/icon/Ic_mail.png';
import Ic_MapPinLine from 'assets/icon/Ic_MapPinLine.png';
import Facebook from 'assets/icon/Facebook.png';
import Linkedin from 'assets/icon/Linkedin.png';
import Youtube from 'assets/icon/Youtube.png';
import Twitter from 'assets/icon/Twitter.png';
import Img_Bct from 'assets/icon/Img_Bct.png';
import Img_Dcma from 'assets/icon/Img_Dcma.png';
import Ic_LogoJPG from 'assets/icon/Ic_Logo.jpg';
import { Paths } from 'routers/paths';
import { Trans, Translation } from 'react-i18next';

function Footer() {

    const renderLink = useCallback((title?: any, href?: any) => {
        return <li>
            <Link to={href}>
                <Translation>
                    {
                        (t, { i18n }) => t(title)

                    }
                </Translation>
            </Link>
        </li>
    }, [])

    const renderTranslation = useCallback((title?: any) => {
        return <li>
            <Translation>
                {
                    (t, { i18n }) => t(title)

                }
            </Translation>
        </li>
    }, [])

    return (
        <div className='footerContainer'>
            <div className='container'>
                <div className='col-xs-12 col-sm-5 col-md-3'>
                    <div className='info_company_us'>
                        <Link to={'/'} title='trang chủ'>
                            <img src={Ic_LogoJPG} alt="trang chủ" className='logo' />
                        </Link>
                        <div className='contact_flow'>
                            <ul>
                                <li>
                                    <img src={Ic_MapPinLine} alt='Ic_MapPinLine' />
                                    No 34, Lane 3, Phan Dinh Giot Street, <br /> La Khe Ward, Ha Dong District, Ha Noi City
                                </li>
                                <li><img src={Ic_globe} alt='Ic_MapPinLine' /> https://dataxmind.com</li>
                                <li><img src={Ic_mail} alt='Ic_MapPinLine' />contact@dataxmind.com</li>
                                <li><img src={Ic_Phone} alt='Ic_MapPinLine' /><Link to={'tel:0326668151'}>+84 32 666 8151</Link></li>
                            </ul>
                        </div>
                        <div className='social'>
                            <div className='list_social'>
                                <Link to={'https://www.facebook.com/dataxmind/'}>
                                    <img src={Facebook} alt='Facebook' />
                                </Link>
                                <Link to={'https://www.linkedin.com/in/richard-do-888b16279/'}>
                                    <img src={Linkedin} alt='Linkedin' />
                                </Link>
                                <Link to={'/'}>
                                    <img src={Youtube} alt='Youtube' />
                                </Link>
                                <Link to={'https://twitter.com/richardctoxmind'}>
                                    <img src={Twitter} alt='Twitter' />
                                </Link>
                            </div>
                            <div className='confirm_img_business'>

                                <a href="//www.dmca.com/Protection/Status.aspx?ID=b8c6cb05-728e-414f-87f7-0b92c1c736ba" title="DMCA.com Protection Status" className="dmca-badge">
                                    <img style={{ width: 150 }} src="https://images.dmca.com/Badges/DMCA_logo-std-btn180w.png?ID=b8c6cb05-728e-414f-87f7-0b92c1c736ba" alt="DMCA.com Protection Status" /></a>
                                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-7 col-md-9'>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-6 col-md-3'>
                            <div className='menu_direct'>
                                <h3>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Features')

                                        }
                                    </Translation>
                                </h3>
                                <ul>
                                    {renderLink('content.Platform')}
                                    {renderLink('content.Marketplace')}
                                    {renderLink('content.DevelopersFT')}
                                    {renderLink('content.CustomerFT')}
                                    {renderLink('content.FeaturesFT')}
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-3'>
                            <div className='menu_direct'>
                                <h3>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Resources')

                                        }
                                    </Translation>
                                </h3>
                                <ul>
                                    {renderLink('content.HowToCreateMobileApp')}
                                    {renderLink('content.HowMuchDoes')}
                                    {renderLink('content.SDKAPIDoes')}
                                    {renderLink('content.Stories')}
                                    {renderLink('content.Blogs')}
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-3'>
                            <div className='menu_direct'>
                                <h3>
                                    <Translation>
                                        {
                                            (t, { i18n }) => t('content.Company')

                                        }
                                    </Translation>
                                </h3>
                                <ul>
                                    {renderLink('content.AboutUs', Paths.about)}
                                    {renderLink('content.ContactUs', Paths.contact)}
                                    {renderLink('content.Reviews')}
                                    {renderLink('content.FAQ')}
                                    {renderLink('content.Terms')}
                                    {renderLink('content.CookiePolicy')}
                                    {renderLink('content.PrivacyPolicy')}
                                    {renderLink('content.Projects')}
                                </ul>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-3'>
                            <div className='menu_direct'>
                                <h3>
                                    <Trans i18nKey='content.Solutions' />
                                </h3>
                                <ul>
                                    {renderLink('content.CustomDevelopment')}
                                    {renderLink('content.eCommerceApps')}
                                    {renderLink('content.Vehicle')}
                                    {renderLink('content.Delivery')}
                                    {renderLink('content.EntertainmentApps')}
                                </ul>
                            </div>
                        </div>

                        <div className='clear'></div>
                    </div>
                </div>
                <div className='col-xs-12 col-md-12'>
                    <div className='coppy_right'>

                        {
                            renderTranslation('content.Copyright')

                        }
                    </div>
                    <div className='descreption'>
                        <p className='text-center'>{renderTranslation('content.Limited')} {renderTranslation('content.BusinessRegistration')} {renderTranslation('content.First')} {renderTranslation('content.IssuingAgency')}</p>
                    </div>
                </div>
                <div className='clear'></div>
            </div>
        </div>
    );
}

export default memo(Footer);

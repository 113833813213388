import React from 'react';
import ImgNotFound from '../../../assets/icon/img_notfound.jpg'
import Ic_Logo from '../../../assets/icon/Ic_Logo.jpg'
import { Link } from 'react-router-dom';
import { Paths } from 'routers/paths';


const NotFound = () => {
    return (
        <div className='content-padding y10'>
            <div className='logo'>
                <Link to={Paths.home}>
                    <img src={Ic_Logo} alt='logo' />
                </Link>
            </div>
            <div className={'center-column'}>
                <img src={ImgNotFound} className='full-image' />
            </div>
        </div>
    );
};
export default NotFound;


import Languages from 'commons/languages';
import { Button } from 'components/button';
import { BUTTON_STYLES } from 'components/button/types';
import { MyTextInput } from 'components/input';
import { TextFieldActions } from 'components/input/types';
import { MyTextAreaInput } from 'components/text-area';
import React from 'react';
import { useRef, useState } from 'react';
import { Modal } from 'antd';
import formValidate from 'utils/form-validate';

function ContactForm() {
    const refName = useRef<TextFieldActions>(null);
    const refEmail = useRef<TextFieldActions>(null);
    const refMsg = useRef<TextFieldActions>(null);

    const handleSubmit = () => {
        const name = refName.current?.getValue()
        const email = refEmail.current?.getValue()
        const msg = refMsg.current?.getValue()

        const errMsgName = formValidate.inputNameEmpty(name, Languages.errorMsg.nameRequired, Languages.errorMsg.userNameRegex)
        const errMsgEmail = formValidate.emailValidate(email)

        refEmail.current?.setErrorMsg(errMsgEmail)
        refName.current?.setErrorMsg(errMsgName)

        if (`${errMsgName}${errMsgEmail}`.length === 0) {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('content', msg);
            fetch('https://formspree.io/f/xlekkykg', {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json'
                }
            }).then(response => response.json())
                .then(data => {
                    if (data.ok) {
                        Modal.success({
                            title: 'Message sent successfully',
                            content: (
                                <div>
                                    <p style={{ margin: 0 }}>We will contact you as soon as possible.</p>
                                    <p style={{ margin: 0 }}>Thank you you have interest.</p>
                                </div>
                            )
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });

        } else {
            return false
        }
    };

    return <div className='box_input_group'>
        <div className='h5 medium blue'>
            {Languages.contact.title}
        </div>
        <MyTextInput
            ref={refName}
            type={'text'}
            placeHolder={Languages.contact.name}
            maxLength={30}
            containerInput={'containerInput'}
        />

        <MyTextInput
            ref={refEmail}
            type={'text'}
            placeHolder={Languages.contact.phone}
        />
        <MyTextAreaInput
            ref={refMsg}
            type={'text'}
            placeHolder={Languages.contact.message}
            maxLength={300}
        />
        <Button
            label={Languages.contact.submit}
            buttonStyle={BUTTON_STYLES.LINEAR_GREEN}
            textStyle={BUTTON_STYLES.WHITE}
            width={100}
            onPress={handleSubmit}
        />

    </div>;
}

export default ContactForm;
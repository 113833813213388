import React from 'react';

const OverlayLoader = () => {
    return (
        <div>
            OverlayLoader
        </div>
    );
};
export default OverlayLoader;

import React, { useCallback } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Img_feedback from 'assets/icon/Img_feedback.png';
import Ic_Linked from 'assets/icon/Ic_linked.png';
import Ic_Facebook from 'assets/icon/Ic_fb.png';
import Ic_Tele from 'assets/icon/Ic_tele.png';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Ic_partner1 from 'assets/icon/Ic_Partner1.jpg';
import Ic_partner2 from 'assets/icon/Ic_Partner2.png';
import Ic_partner3 from 'assets/icon/Ic_Partner3.png';
import Ic_partner4 from 'assets/icon/Ic_Partner4.png';
import Ic_partner5 from 'assets/icon/Ic_Partner5.png';
import Ic_partner6 from 'assets/icon/Ic_partner6.png';
import Ic_partner7 from 'assets/icon/Ic_partner7.png';
import Ic_partner8 from 'assets/icon/Ic_Partner8.jpg';
import Ic_partner9 from 'assets/icon/Ic_Partner9.png';


const Section_7 = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]

    };

    const settingsPartner = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1903,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    };

    


    return (
        <section className='section section_7 '>
            <div className='wrapper_box_team'>
                <div className='container'>
                    <div className='team_box_show' style={{ display: 'none' }}>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2>Who are our team?</h2>
                            </div>
                        </div>
                        <div className='list_show_team'>
                            <AnimationOnScroll
                                animateIn="animate__fadeInDown"
                                offset={0}
                                duration={2}
                            >
                                <Slider {...settings}>
                                    <div className='item_person'>
                                        <div className='box_effect_image'>
                                            <div className='border-box-around'>
                                                <img src={Img_feedback} alt='feedback' />
                                                <div className='social_link_customer'>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Linked} alt="linked" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Facebook} alt="fb" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Tele} alt="linked" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position_job'>
                                            <h4>Jennifer Shapiro</h4>
                                            <span>CTO</span>
                                        </div>
                                    </div>
                                    <div className='item_person'>
                                        <div className='box_effect_image'>
                                            <div className='border-box-around'>
                                                <img src={Img_feedback} alt='feedback' />
                                                <div className='social_link_customer'>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Linked} alt="linked" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Facebook} alt="fb" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Tele} alt="linked" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position_job'>
                                            <h4>Jennifer Shapiro</h4>
                                            <span>CTO</span>
                                        </div>
                                    </div>
                                    <div className='item_person'>
                                        <div className='box_effect_image'>
                                            <div className='border-box-around'>
                                                <img src={Img_feedback} alt='feedback' />
                                                <div className='social_link_customer'>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Linked} alt="linked" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Facebook} alt="fb" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Tele} alt="linked" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position_job'>
                                            <h4>Jennifer Shapiro</h4>
                                            <span>CTO</span>
                                        </div>
                                    </div>
                                    <div className='item_person'>
                                        <div className='box_effect_image'>
                                            <div className='border-box-around'>
                                                <img src={Img_feedback} alt='feedback' />
                                                <div className='social_link_customer'>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Linked} alt="linked" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Facebook} alt="fb" />
                                                    </Link>
                                                    <Link to={'/'} >
                                                        <img src={Ic_Tele} alt="linked" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='position_job'>
                                            <h4>Jennifer Shapiro</h4>
                                            <span>CTO</span>
                                        </div>
                                    </div>
                                </Slider>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className='partner_box'>
                        <div className='wrap_box_style_cover'>
                            <div className='head'>
                                <h2><Translation>
                                    {
                                        (t, { i18n }) => t('content.Partners')

                                    }
                                </Translation></h2>
                            </div>
                            
                        </div>
                        <div className='slider_partner'>
                            <Slider {...settingsPartner}>
                                <div className='item_partner'>
                                    <img src={Ic_partner1} alt='parnter' />
                                </div>
                                <div className='item_partner'>
                                    <img src={Ic_partner2} alt='parnter' />
                                </div>
                                {/* <div className='item_partner'>
                                    <img src={Ic_partner3} alt='parnter' />
                                </div> */}
                                <div className='item_partner'>
                                    <img src={Ic_partner4} alt='parnter' />
                                </div>
                                {/* <div className='item_partner'>
                                    <img src={Ic_partner5} alt='parnter' />
                                </div> */}
                                {/* <div className='item_partner'>
                                    <img src={Ic_partner6} alt='parnter' />
                                </div> */}
                                <div className='item_partner'>
                                    <img src={Ic_partner7} alt='parnter' />
                                </div>
                                <div className='item_partner'>
                                    <img src={Ic_partner8} alt='parnter' />
                                </div>
                                <div className='item_partner'>
                                    <img src={Ic_partner9} alt='parnter' />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_7
import { Navbar } from 'components/menu';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import img_contact_1 from '../../assets/icon/img_contact_1.png';
import img_contact_2 from '../../assets/icon/img_contact_2.jpeg';
import ContactForm from './contact-form';
import Footer from 'components/footer';

function Contact() {
    return (
        <div className='column'>
            <Navbar />
            <div className={'center page-padding'}>
                <img src={img_contact_1} className={'full-image'} />
                <img src={img_contact_2} className={'full-image'} />
            </div>
            <div className='content-padding y10'>
                <ContactForm />
            </div>
            <Footer/>
        </div>
    );
}

export default Contact;

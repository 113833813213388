export const Paths = {
    //common
    home: '/',
    any: '*',
    about: '/about',
    contact: '/contact',

    //auth
    login: '/login',
    register: '/register',

    //account
    profile: '/profile'
};
